@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}
html,
body {
  min-height: 100% !important;
  height: 100%;
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
  overflow-x: hidden;
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

@font-face {
  font-family: 'VesperLibre-Regular';
  src: url('./assetes/fonts/VesperLibre-Regular.ttf');
  font-display: swap;
}

.VesperLibre-Regular {
  font-family: 'VesperLibre-Regular';
}

:where(.css-dev-only-do-not-override-mzwlov).ant-card, :where(.css-dev-only-do-not-override-mzwlov)[class^="ant-table"], :where(.css-dev-only-do-not-override-mzwlov)[class*=" ant-table"]{
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.inter-font{
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray; 
}

.has-error >.ant-select-selector {
  border: 1px solid red !important;
}

/* antd css override start */

.small-options .ant-select-item {
  font-size: 12px;
  line-height: 1.5;
  padding-inline: 5px;
  min-height: 20px;
}

.small-options .ant-select-item-option-content {
  white-space: break-spaces;
  text-overflow: unset;
}

.small-options.ant-picker-dropdown {
  font-size: 12px;
}

.small-options.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 5px;
}

.small-options.ant-picker-dropdown .ant-picker-content td {
  min-width: unset;
  max-width: 16px;
}

tr.ant-table-row:has(.product-inactive-tag) td.ant-table-cell,
tr.ant-table-row:has(.product-inactive-tag) td.ant-table-cell:hover,
.ant-table-wrapper .ant-table-tbody .ant-table-row:has(.product-inactive-tag) >.ant-table-cell-row-hover {
  background-color: #ffeee9;
}

.bottom-left-total {
  position: absolute;
  left: 10px;
}
/* antd css override end */